import { RouteObject } from 'react-router-dom'

import { amazonRoutes } from './amazonRoutes'
import { gamConnectionRoutes } from './gamConnectionRoutes'
import { generalRoutes } from './generalRoutes'
import { prebidRoutes } from './prebidRoutes'
import { protectionRoutes } from './protectionRoutes'
import { supplyChainRoutes } from './supplyChainRoutes'
import { viewportRoutes } from './viewportRoutes'

export const globalSetupRoutes: RouteObject[] = [
  ...amazonRoutes,
  ...gamConnectionRoutes,
  ...generalRoutes,
  ...prebidRoutes,
  ...protectionRoutes,
  ...supplyChainRoutes,
  ...viewportRoutes,
]
