import { CSSObject, Theme } from '@mui/material'

import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'

export type YieldbirdStatusColor = {
  status: YieldbirdStatus
  theme: Theme
}

export const yieldbirdStatusColor = ({
  status,
  theme,
}: YieldbirdStatusColor): CSSObject['color'] => {
  const {
    palette: {
      error: { main: error },
      success: { main: success },
      warning: { main: warning },
    },
  } = theme

  switch (status) {
    case 'available': {
      return success
    }
    case 'denied': {
      return error
    }
    case 'requested': {
      return warning
    }
  }
}
