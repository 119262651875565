import { RouteObject } from 'react-router-dom'

import { GoogleAdManagerConnectionPage } from 'features/globalSetup/pages/GoogleAdManagerConnectionPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const gamConnectionRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-googleAdManagerConnection'
        page={<GoogleAdManagerConnectionPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.GAM_CONNECTION.ROOT,
  },
]
