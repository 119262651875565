import { z } from 'zod'

const DOMAIN = {
  ID: z.string(),
  PREBID_MODULE_PARAM: {
    NAME: z.string(),
    VALUE: z.string().or(z.number()),
  },
} as const

const domainPrebidModuleParams = z.record(
  DOMAIN.PREBID_MODULE_PARAM.NAME,
  DOMAIN.PREBID_MODULE_PARAM.VALUE,
)
export type SchemaDomainPrebidModuleParams = z.infer<typeof domainPrebidModuleParams>

export const domainPrebidModuleAttributes = z.object({
  enabled: z.boolean(),
  params: domainPrebidModuleParams,
})
export type SchemaDomainPrebidModuleAttributes = z.infer<typeof domainPrebidModuleAttributes>

export const schema = z.object({
  domainPrebidModules: z.record(DOMAIN.ID, domainPrebidModuleAttributes),
})
export type Schema = z.infer<typeof schema>

export const schemaDefaults = (): Schema => ({
  domainPrebidModules: {},
})
