import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_DOMAIN_BIDDER } from './api/query'
import { DomainBidderSlice, Props, UseGetDomainBidder } from './types'

export const useGetDomainBidder = ({ id }: Props): UseGetDomainBidder => {
  const [domainBidder, setDomainBidder] = useState<DomainBidderSlice>()

  useEffect(() => {
    if (id) {
      query({
        variables: { id },
      })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_DOMAIN_BIDDER, {
    onCompleted: ({ node: domainBidder }): void => {
      if (domainBidder) {
        setDomainBidder(fromDTO(domainBidder))
      }
    },
    variables: { id },
  })

  return { domainBidder, loading }
}
