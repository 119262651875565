import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { useAbility } from 'providers/casl'
import { fromDTO, sortToDTO } from './api/mapper'
import { GET_AD_UNIT_AMAZONS } from './api/query'
import { AdUnitAmazonSliceDTO } from './api/types'
import { AdUnitAmazonSlice, Props, UseGetAdUnitAmazons } from './types'

export const useGetAdUnitAmazons = ({
  domainAmazonId,
  filters,
  first,
  last,
  sort,
}: Props): UseGetAdUnitAmazons => {
  const [adUnitAmazons, setAdUnitAmazons] = useState<AdUnitAmazonSlice[]>([])
  const [count, setCount] = useState<number>(0)
  const ability = useAbility()

  useEffect(() => {
    if (domainAmazonId !== undefined) {
      query({
        variables: {
          domainAmazonId,
          first,
          last,
          sort: sortToDTO(sort),
          terms: filters,
        },
      })
    }
  }, [domainAmazonId, filters, first, last, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_AD_UNIT_AMAZONS(ability), {
    onCompleted: ({ adUnitAmazons: { nodes, totalCount: count } }): void => {
      if (nodes) {
        setAdUnitAmazons(
          nodes.map((node: AdUnitAmazonSliceDTO): AdUnitAmazonSlice => fromDTO(node)),
        )
        setCount(count)
      }
    },
  })

  return {
    adUnitAmazons,
    count,
    loading,
  }
}
