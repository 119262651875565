import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { Schema } from '../schema'

export const useCollectInventoryConnectionDirtyRows = (): void => {
  const { inventoryConnectionDirtyRows, setInventoryConnectionDirtyRows } =
    useContext(PrebidModulesContext)

  const {
    formState,
    formState: { dirtyFields },
  } = useFormContext<Schema>()

  useEffect(() => {
    if (setInventoryConnectionDirtyRows !== undefined && !_.isEmpty(dirtyFields)) {
      const dirtyRowsIds = _.keys(dirtyFields.domainPrebidModules)

      if (!_.isEqual(inventoryConnectionDirtyRows, dirtyRowsIds)) {
        setInventoryConnectionDirtyRows(dirtyRowsIds)
      }
    }
  }, [formState, setInventoryConnectionDirtyRows]) // eslint-disable-line react-hooks/exhaustive-deps
}
