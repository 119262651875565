import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { DomainBidderErrors } from './types'

export const DOMAIN_BIDDER_ERROR_FRAGMENT: TypedDocumentNode<DomainBidderErrors> = gql`
  ${ERROR_FRAGMENT}

  fragment DomainBidderErrorFragment on DomainBidderErrors {
    domainBidderId

    adUnitBidders {
      adUnitBidderId
      errors {
        ...ErrorFragment
      }
    }
  }
`
