import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainPrebidModuleSliceDTO } from './types'

export const DOMAIN_PREBID_MODULE_FRAGMENT: TypedDocumentNode<DomainPrebidModuleSliceDTO> = gql`
  fragment DomainPrebidModuleFragment on DomainPrebidModule {
    domain {
      id
      name
    }
    enabled
    id
    params
    paramsSchema {
      label
      name
      required
      tooltip
      type
    }
    updatedAt
  }
`
