import _ from 'lodash'

import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModuleSlice } from '../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../schema'

export const prebidModuleDomainsToSchema = ({
  moduleType,
  prebidModuleDomains,
}: {
  moduleType: PrebidModuleType
  prebidModuleDomains: DomainPrebidModuleSlice[]
}): Schema['domainPrebidModules'] =>
  _.chain(prebidModuleDomains)
    .keyBy('id')
    .mapValues((prebidModuleDomain: DomainPrebidModuleSlice) => {
      const { enabled, params } = prebidModuleDomain

      return {
        enabled,
        params: moduleType !== 'general' ? _.mapValues(params, 'value') : {},
      }
    })
    .value()
