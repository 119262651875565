import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'

import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { PageForAdUnitTransferListDTO } from 'features/inventory/api/types/pageForAdUnitTransferListDTO'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { pageForAdUnitTransferListFromDTO } from './api/mapper'
import { GET_PAGES_FOR_AD_UNIT_TRANSFER_LIST } from './api/query'
import {
  PageForAdUnitTransferList,
  UseGetPagesForAdUnitTransferList,
  UseGetPagesForAdUnitTransferListProps,
} from './types'

export const useGetPagesForAdUnitTransferList = ({
  domainId,
}: UseGetPagesForAdUnitTransferListProps): UseGetPagesForAdUnitTransferList => {
  const { workspaceId } = useWorkspaceParam()
  const { adUnit } = useContext(AddEditAdUnitContext)
  const [all, setAll] = useState<PageForAdUnitTransferList[]>([])

  const active: PageForAdUnitTransferList[] = adUnit?.pages ?? []

  useEffect(() => {
    if (domainId) {
      fetch({
        variables: {
          domainId,
          workspaceId,
        },
      })
    }
  }, [domainId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetch, { loading }] = useLazyQuery(GET_PAGES_FOR_AD_UNIT_TRANSFER_LIST, {
    onCompleted: ({ pages: { nodes: pages } }) => {
      setAll(
        pages.map(
          (page: PageForAdUnitTransferListDTO): PageForAdUnitTransferList =>
            pageForAdUnitTransferListFromDTO(page),
        ),
      )
    },
  })

  return {
    active,
    all,
    available: _.differenceBy(all, active, 'value'),
    loading,
  }
}
