import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { EditSchema } from '../../../schemas/editSchema'

export const ModuleEnabledSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.form.enabled',
  })
  const ability = useAbility()
  const { control } = useFormContext<EditSchema>()

  return (
    <Paper>
      <SectionHeader
        subtitle={t('header.subtitle')}
        title={t('header.title')}
        titleEndAdornment={
          <Switch
            control={control}
            disabled={ability.cannot('update', 'GlobalSetupFeature-userId')}
            name='enabled'
          />
        }
      />
    </Paper>
  )
}
