import { kebabCase } from 'change-case'
import { KebabCase } from 'type-fest'

import { Ability } from 'providers/casl'
import { PrebidModuleType } from '../types/prebidModule'

type CanAccessProps = {
  ability: Ability
  moduleType: PrebidModuleType
}

export const canAccess = ({ ability, moduleType }: CanAccessProps): boolean =>
  ability.can(
    'access',
    `GlobalSetupPage-prebid-modules-${kebabCase(moduleType) as KebabCase<PrebidModuleType>}-edit`,
  )
