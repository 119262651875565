import { z } from 'zod'

import { PerformanceReport, UserSlice } from '../../hooks/useGetPerformanceReport/types'

export const schema = z.object({
  emails: z.array(z.string()),
  id: z.string(),
  performanceReportUrl: z.string().optional(),
})

export type Schema = z.infer<typeof schema>

export const defaults = (data: PerformanceReport): Schema => {
  const { id, performanceReportUrl, users } = data

  return {
    emails: users.map((user: UserSlice): string => user.email),
    id,
    performanceReportUrl: performanceReportUrl || '',
  }
}
