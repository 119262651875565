import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitBidderDTO } from 'features/globalSetup/api/types/adUnitBidderDTO'
import { Ability } from 'providers/casl'
import { permitFields } from 'providers/graphql/utils'

export const AD_UNIT_BIDDER_FRAGMENT = (
  ability: Ability,
): TypedDocumentNode<AdUnitBidderDTO> => gql`
  fragment AdUnitBidderFragment on AdUnitBidder {
    id
    adUnit {
      id
      divId
      path
      ${permitFields<AdUnitBidderDTO['adUnit']>({
        unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery'),
      })}
    }
    csEnabled
    csParams
    s2sEnabled
    s2sParams
    updatedAt
  }
`
