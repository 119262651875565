import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const UPDATE_PERFORMANCE_REPORT: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${ERROR_FRAGMENT}

  mutation updatePerformanceReportUrl($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        id
        performanceReportUrl
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`
