import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { AdUnitAmazonSlice, AdUnitAmazonSortBy } from '../types'
import { AdUnitAmazonSliceDTO, AdUnitAmazonSortByDTO } from './types'

type From = AdUnitAmazonSliceDTO
type To = AdUnitAmazonSlice

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({
    from,
  }).transform((adUnitAmazon: From): To => {
    const {
      adUnit: { divId, path, unfilledRecoveryAdUnit },
      enabled,
      id,
      updatedAt,
    } = adUnitAmazon

    return {
      divId: divId || undefined,
      enabled,
      id,
      path,
      unfilledRecoveryAdUnit,
      updatedAt: new Date(updatedAt),
    }
  })

export const sortToDTO = (
  sort: SortInput<AdUnitAmazonSortBy>,
): SortInput<AdUnitAmazonSortByDTO> => {
  const { by } = sort

  if (by === 'divId') {
    return { ...sort, by: 'adUnitDivId' }
  } else if (by === 'path') {
    return { ...sort, by: 'adUnitPath' }
  } else {
    return { ...sort, by }
  }
}
