import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { DomainPrebidModuleSchemaParameter } from 'features/inventory/domain/types/domainPrebidModule'
import { DomainPrebidModuleSlice, DomainPrebidModuleSortBy } from '../types'
import { DomainPrebidModuleSliceDTO, DomainPrebidModuleSortByDTO } from './types'
import { mergeParamsWithSchema, paramsSchemaFromDTO } from './utils'

export const domainPrebidModulesFromDTO = (
  from: DomainPrebidModuleSliceDTO,
): DomainPrebidModuleSlice =>
  mapDTO<DomainPrebidModuleSliceDTO, DomainPrebidModuleSlice>({ from }).transform(
    (data: DomainPrebidModuleSliceDTO): DomainPrebidModuleSlice => {
      const {
        domain: { name },
        enabled,
        id,
        params,
        paramsSchema: paramsSchemaDTO,
        updatedAt,
      } = data

      const paramsSchema: DomainPrebidModuleSchemaParameter[] = paramsSchemaFromDTO(paramsSchemaDTO)

      return {
        domain: { name },
        enabled,
        id,
        params: mergeParamsWithSchema(params, paramsSchema),
        paramsSchema,
        updatedAt: new Date(updatedAt),
      }
    },
  )

export const sortToDTO = (
  sort: SortInput<DomainPrebidModuleSortBy>,
): SortInput<DomainPrebidModuleSortByDTO> => {
  const { by } = sort

  if (by === 'domain.name') {
    return { ...sort, by: 'domainName' }
  } else {
    return { ...sort, by }
  }
}
