import _ from 'lodash'
import { FieldErrors } from 'react-hook-form'
import { z } from 'zod'

import { MutationInputError } from 'providers/graphql'

export type AttributeErrors = {
  attributeId: string
  errors: MutationInputError[]
}

const ATTRIBUTE = {
  ID: z.string(),
  PARAMETER: {
    NAME: z.string(),
    VALUE: z.string().or(z.number()),
  },
} as const

export const schema = z.object({
  attributesErrors: z.record(
    ATTRIBUTE.ID,
    z.object({
      params: z.record(ATTRIBUTE.PARAMETER.NAME, ATTRIBUTE.PARAMETER.VALUE),
    }),
  ),
})
export type ErrorsSchema = z.infer<typeof schema>

export const attributesErrorsFromDTOtoSchema = (
  errors: AttributeErrors[],
): FieldErrors<ErrorsSchema> => ({
  attributesErrors: _.chain(errors)
    .mapKeys(
      (attributeError: AttributeErrors): AttributeErrors['attributeId'] =>
        attributeError.attributeId,
    )
    .mapValues(
      ({
        errors,
      }: AttributeErrors): Record<string, Record<string, Pick<MutationInputError, 'message'>>> =>
        _.chain(errors)
          .groupBy(
            (err: MutationInputError): MutationInputError['attribute'] =>
              err.attribute.split('.')[0],
          )
          .mapValues(
            (errors: MutationInputError[]): Record<string, Pick<MutationInputError, 'message'>> =>
              _.chain(errors)
                .mapKeys(
                  (err: MutationInputError): MutationInputError['attribute'] =>
                    err.attribute.split('.')[1],
                )
                .mapValues(
                  (err: MutationInputError): Pick<MutationInputError, 'message'> => ({
                    message: err.message,
                  }),
                )
                .value(),
          )
          .value(),
    )
    .value(),
})
