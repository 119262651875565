import _, { isBoolean, isNumber, isString } from 'lodash'

import { Primitive } from 'types/primitive'
import { parseValidJSON } from 'utils/json'
import { isNumericString, withoutOuterQuotes } from 'utils/string'

export const parsePrimitiveValue = (value: unknown, type: Primitive): number | string => {
  let parsedValue: number | string

  if (_.isNil(value)) {
    parsedValue = ''
  } else if (type === 'string') {
    parsedValue = withoutOuterQuotes(JSON.stringify(value))
  } else {
    parsedValue = value as number
  }

  return parsedValue
}

export const parseSchemaParametersToDTO = (
  params: Record<string, boolean | number | string>,
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(params).map(
      ([key, value]: [string, boolean | number | string]): [string, unknown] => [
        key,
        parseSchemaParameter(value),
      ],
    ),
  )

const parseSchemaParameter = (value: boolean | number | string): unknown => {
  if (isBoolean(value) || isNumber(value)) {
    return value
  } else if (isString(value)) {
    if (value.length === 0) {
      return null
    } else if (isNumericString(value)) {
      return value.toString()
    } else {
      return parseValidJSON(value)
    }
  }

  throw Error('Unsupported value type')
}
