import { memo } from 'react'

import { Cell } from './Cell'
import { CellProps } from './types'

const EXCLUDED_COLUMNS: string[] = ['action', 'expander']

export const MemoizedCell = memo(
  Cell,
  <T,>(prevProps: CellProps<T>, nextProps: CellProps<T>): boolean => {
    const [
      {
        cell: { getValue: prevValue, id: prevId },
      },
      {
        cell: { getValue: nextValue, id: nextId },
      },
    ] = [prevProps, nextProps]

    const containsExcludedColumns = EXCLUDED_COLUMNS.some(
      (keyword: string): boolean => prevId.includes(keyword) || nextId.includes(keyword),
    )

    if (containsExcludedColumns) {
      return false
    }

    return prevId === nextId && prevValue() === nextValue()
  },
) as typeof Cell
