import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { BoxWithLogo, StickyBox } from 'components/Box'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import { bidderLogos } from 'constants/bidderLogos'
import {
  WorkspaceBidderContext,
  WorkspaceBidderContextType,
} from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { isYieldbirdBidder } from 'features/globalSetup/forms/Prebid/Bidders/utils'
import { useGetWorkspaceBidder } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/hooks/useGetWorkspaceBidder'
import { AdUnitBidder } from 'features/globalSetup/types/adUnitBidder'
import { WorkspaceBidder } from 'features/globalSetup/types/workspaceBidder'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { BidderEditParams, BidderEditTabs, useTabs } from './hooks/useTabs'

export const EditBidderPage = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { id: workspaceBidderId, tab: urlTab, workspaceId } = useParams<BidderEditParams>()
  const tabs: Tab<BidderEditTabs>[] = useTabs()
  const { workspaceBidder } = useGetWorkspaceBidder({ id: workspaceBidderId })
  const [accountType, setAccountType] = useState<WorkspaceBidder['accountType']>('own')
  const [inventoryConnectionDirtyRows, setInventoryConnectionDirtyRows] = useState<
    AdUnitBidder['id'][]
  >([])

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-bidders-form-edit',
  })

  useEffect(() => {
    if (workspaceBidder) {
      setAccountType(workspaceBidder.accountType)
    }
  }, [workspaceBidder])

  if (
    [urlTab, workspaceBidderId, workspaceId].some((param?: string): boolean => param === undefined)
  ) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (workspaceBidder === undefined) {
    return <Loading />
  }

  const {
    bidder: { name },
    bidderCode,
  } = workspaceBidder

  const contextProps: WorkspaceBidderContextType = {
    accountType,
    inventoryConnectionDirtyRows,
    setAccountType,
    setInventoryConnectionDirtyRows,
    workspaceBidder,
    ...commonContextProps,
  }

  return (
    <WorkspaceBidderContext.Provider value={contextProps}>
      <StickyBox>
        <WorkspaceBidderContext.Consumer>
          {(ctxProps: WorkspaceBidderContextType): JSX.Element => (
            <PageHeader
              actions={
                <SaveButton
                  {...ctxProps}
                  can={ability.can('update', 'GlobalSetupFeature-bidder')}
                  text={
                    isYieldbirdBidder(accountType)
                      ? t('common:form.action.sendRequest')
                      : t('common:form.action.save')
                  }
                />
              }
              backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ROOT, { workspaceId })}
              title={bidderCode}
              titleAdornment={{
                start: <BoxWithLogo logo={{ size: 'big', svg: bidderLogos[name] }} />,
              }}
            />
          )}
        </WorkspaceBidderContext.Consumer>

        <Tabs
          path={ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT}
          tabs={tabs}
        />
      </StickyBox>

      {getPage<BidderEditTabs>(tabs, urlTab, 'general')}
    </WorkspaceBidderContext.Provider>
  )
}
