import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Slider } from 'components/Form'
import { SliderMark } from 'components/Form/Slider/types'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useS2sTimeoutMultiplierMarks } from './hooks/useS2sTimeoutMultiplierMarks'
import { Schema } from './schema'
import { AdvancedPrebidSection } from './sections/AdvancedPrebidSection'
import { ConsentManagementSection } from './sections/ConsentManagementSection'
import { NonConsentTrafficMonetizationSection } from './sections/NonConsentTrafficMonetizationSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { control } = useFormContext<Schema>()

  const s2sTimeoutMultiplierMarks: SliderMark[] = useS2sTimeoutMultiplierMarks()

  const ability = useAbility()
  const isDisabled = ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')

  return (
    <>
      <Paper>
        <BoxForm>
          <SectionHeader
            subtitle={t('prebid.defaults.timeouts.subtitle')}
            title={t('prebid.defaults.timeouts.title')}
          />

          <NumberField
            control={control}
            disabled={isDisabled}
            label={t('prebid.defaults.timeouts.prebidTimeout.label')}
            name='prebidTimeout'
            tooltip={{
              content: t('prebid.defaults.timeouts.prebidTimeout.tooltip'),
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE_URL.TIMEOUTS,
              },
            }}
          />

          <Slider
            control={control}
            disabled={isDisabled}
            label={t('prebid.defaults.timeouts.s2sTimeoutMultiplier.label')}
            marks={s2sTimeoutMultiplierMarks}
            name='s2sTimeoutMultiplier'
            tooltip={{
              content: t('prebid.defaults.timeouts.s2sTimeoutMultiplier.tooltip'),
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE_URL.TIMEOUTS,
              },
            }}
            withInput
          />
        </BoxForm>
      </Paper>

      <ConsentManagementSection />
      <NonConsentTrafficMonetizationSection />
      <AdvancedPrebidSection />
    </>
  )
}
