import { Theme } from '@mui/material'
import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { useMergeFormData } from 'components/Form'
import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { calculatePagination } from 'providers/graphql'
import {
  AdUnitAmazonSlice,
  AdUnitAmazonSortBy,
  useGetAdUnitAmazons,
} from '../../hooks/useGetAdUnitAmazons'
import { AdUnitAmazons, Schema } from '../../schema'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { Props } from './types'
import { useColumns } from './useColumns'

export const AdUnitAmazonsTable = ({ filters }: Props): JSX.Element => {
  const { watch } = useFormContext<Schema>()
  const columns = useColumns()

  const { page, rowsPerPage, setPage, ...usePaginationProps } = usePagination({
    resetOnChange: [filters],
  })
  const { props: sortProps, sort } = useSort<AdUnitAmazonSlice, AdUnitAmazonSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { id } = watch()
  const { adUnitAmazons, count, loading } = useGetAdUnitAmazons({
    domainAmazonId: id,
    filters,
    ...calculatePagination({ page, rowsPerPage }),
    sort,
  })

  const formData: AdUnitAmazons = _.chain(adUnitAmazons).keyBy('id').mapValues('enabled').value()
  useMergeFormData<Schema>({
    data: formData,
    path: 'adUnitAmazonsAttributes',
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    ...usePaginationProps,
  }

  return (
    <Table<AdUnitAmazonSlice>
      columns={columns}
      data={adUnitAmazons}
      data-cy='ad-unit-amazons-table'
      loading={loading}
      meta={{
        styleRow: (row: Row<AdUnitAmazonSlice>, theme: Theme) =>
          row.original.unfilledRecoveryAdUnit ? { backgroundColor: theme.palette.cream.main } : {},
      }}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}
