import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import _ from 'lodash'
import { Controller, FieldErrors, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { TagsFieldProps } from './types'

export const TagsField = <T extends FieldValues>({
  control,
  disabled,
  disabledFieldTooltip,
  name,
  options,
  tooltip: tooltipProps,
  ...textFieldProps
}: TagsFieldProps<T>): JSX.Element => {
  const calculatedWidth: number = inputWidth[textFieldProps.width ?? 'md']

  const containerSxProps: SxProps<Theme> = {
    minWidth: calculatedWidth,
  }

  const inputSxProps: SxProps<Theme> = {
    minWidth: calculatedWidth,
    width: calculatedWidth,
  }

  const chipSxProps = (isValid: boolean): SxProps<Theme> =>
    isValid
      ? {
          backgroundColor: ({ palette: { grey } }) => grey[200],
          color: ({ palette: { black } }) => black.main,
        }
      : {
          backgroundColor: ({ palette: { error } }) => error.main,
          color: ({ palette: { white } }) => white.main,
        }

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          error={Boolean(error)}
          sx={containerSxProps}
        >
          <Stack
            direction='row'
            sx={containerSxProps}
          >
            <Autocomplete
              disableClearable
              disabled={disabled}
              freeSolo
              id={name}
              multiple
              onChange={(_, value: string[]): void => onChange(value)}
              options={options || []}
              renderInput={(props: AutocompleteRenderInputParams): JSX.Element => {
                const Field = (
                  <TextField
                    {...props}
                    {...textFieldProps}
                    error={Boolean(error)}
                    sx={inputSxProps}
                  />
                )

                return disabledFieldTooltip && disabled ? (
                  <Tooltip
                    {...disabledFieldTooltip}
                    placement='top'
                  >
                    {Field}
                  </Tooltip>
                ) : (
                  Field
                )
              }}
              renderTags={(value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
                value.map((option: string, index: number): JSX.Element => {
                  let isValid: boolean = false
                  if (_.isNil(error)) {
                    isValid = true
                  } else if (_.isPlainObject(error)) {
                    isValid = _.isEmpty((error as FieldErrors<T>)[option])
                  }

                  return (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      sx={chipSxProps(isValid)}
                    />
                  )
                })
              }
              sx={containerSxProps}
              value={value}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}
          </Stack>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
