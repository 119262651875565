import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { GET_WORKSPACES } from './api/query'
import { WorkspaceSliceDTO } from './api/types'
import { mapFromDTO } from './mapper'
import { UseGetWorkspaceOptions, WorkspaceSlice } from './types'

export const useGetWorkspaceOptions = (): UseGetWorkspaceOptions => {
  const [workspaceOptions, setWorkspaceOptions] = useState<SelectOption<WorkspaceSlice>[]>([])

  const { loading } = useQuery(GET_WORKSPACES, {
    onCompleted: ({ workspaces: { nodes: workspaces } }): void => {
      if (workspaces) {
        setWorkspaceOptions(
          workspaces.map(
            (workspace: WorkspaceSliceDTO): SelectOption<WorkspaceSlice> => mapFromDTO(workspace),
          ),
        )
      }
    },
  })

  return {
    loading,
    workspaceOptions,
  }
}
