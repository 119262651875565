import { mapDTO } from '@twistezo/ts-dto-mapper'

import { PerformanceReport } from '../types'
import { PerformanceReportDTO } from './types'

export const fromDTO = (from: PerformanceReportDTO): PerformanceReport =>
  mapDTO<PerformanceReportDTO, PerformanceReport>({ from }).transform(
    ({ performanceReportUrl, ...rest }: PerformanceReportDTO): PerformanceReport => ({
      ...rest,
      performanceReportUrl: performanceReportUrl || undefined,
    }),
  )
