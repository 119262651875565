import { OpenInNew } from '@mui/icons-material'
import { Row } from '@tanstack/react-table'

import { Link } from 'components/Link'
import { WorkspaceForTable } from '../types'
import { isWorkspaceRow } from './utils'

export const PipedriveNavLink = ({ depth, original: { pipedriveLink } }: Row<WorkspaceForTable>) =>
  isWorkspaceRow(depth) ? (
    <Link
      newTab
      to={pipedriveLink}
    >
      <OpenInNew />
    </Link>
  ) : (
    <></>
  )
