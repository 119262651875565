import { CellContext } from '@tanstack/react-table'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { NumberField, TextField } from 'components/Form'
import { TextFieldProps } from 'components/Form/TextField'
import Loading from 'components/Loading'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { DomainPrebidModuleParameter } from 'features/inventory/domain/types/domainPrebidModule'
import { useAbility } from 'providers/casl'
import { DomainPrebidModuleSlice } from '../../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../../schema'

type FieldPath = `domainPrebidModules.${string}.params.${string}`

export const ParamInputCell = ({
  cellContext: {
    cell: { getValue },
    row: {
      original: { id },
    },
  },
}: {
  cellContext: CellContext<DomainPrebidModuleSlice, unknown>
}): JSX.Element | undefined => {
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const ability = useAbility()
  const { control, getValues, register } = useFormContext<Schema>()

  if (!workspacePrebidModule) {
    return <Loading />
  }

  const { name, type, value } = getValue() as DomainPrebidModuleParameter

  const fieldPath: FieldPath = `domainPrebidModules.${id}.params.${name}`

  if (getValues(fieldPath) === undefined) {
    register(fieldPath, { value })
  }

  const { account } = workspacePrebidModule.prebidModule
  const cannotEdit: boolean = ability.cannot(
    'update',
    `GlobalSetupField-prebid-modules-inventory-connection-params-${account}`,
  )

  const inputProps: TextFieldProps<Schema> = {
    control,
    disabled: cannotEdit,
    key: `${id}-${name}`,
    name: fieldPath,
    useTooltipForErrors: true,
  }

  return type === 'string' ? (
    <TextField
      {...inputProps}
      width='sm'
    />
  ) : (
    <NumberField
      {...inputProps}
      type={type}
      width='sm'
    />
  )
}
