import { ProductFruits } from 'react-product-fruits'
import { useSelector } from 'react-redux'

import { RootState } from 'providers/redux'

export const ProductFruitsProvider = (): JSX.Element | null => {
  const { user } = useSelector((state: RootState) => state.user)

  const workspaceCode: string | undefined = import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE

  if (!user || !workspaceCode) {
    return null
  }

  return (
    <ProductFruits
      language='en'
      user={{ username: user.id }}
      workspaceCode={workspaceCode}
    />
  )
}
