import { RouteObject } from 'react-router-dom'

import { ViewportPage } from 'features/globalSetup/features/viewport/pages/ViewportPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const viewportRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-viewport-list'
        page={<ViewportPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.VIEWPORT,
  },
]
