import { mapDTO } from '@twistezo/ts-dto-mapper'

import {
  Schema,
  SchemaAdUnitBidderProps,
} from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitBidder } from 'features/globalSetup/types/adUnitBidder'
import { parseSchemaParametersToDTO } from 'features/globalSetup/utils/parameter'
import { AdUnitBidderAttribute, Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (data: From, dirtyRowsIds?: AdUnitBidder['id'][]): To =>
  mapDTO<From, To>({ from: data }).transform((data: From): To => {
    const {
      adUnitBidders,
      domainIds: { domainBidderId },
      ...rest
    } = data

    const parsedAttributes: AdUnitBidderAttribute[] = parseAttributesToDTO(adUnitBidders)

    let filteredByDirty: AdUnitBidderAttribute[] = []
    if (dirtyRowsIds !== undefined && dirtyRowsIds.length > 0) {
      filteredByDirty = parsedAttributes.filter(({ id }: AdUnitBidderAttribute) =>
        dirtyRowsIds.includes(id),
      )
    }

    return {
      adUnitBiddersAttributes: filteredByDirty,
      id: domainBidderId,
      ...rest,
    }
  })

const parseAttributesToDTO = (adUnitBidders: Schema['adUnitBidders']): AdUnitBidderAttribute[] =>
  Object.entries(adUnitBidders).map(
    ([key, { csEnabled, csParams, s2sEnabled, s2sParams }]: [
      key: keyof Schema['adUnitBidders'],
      SchemaAdUnitBidderProps,
    ]): AdUnitBidderAttribute => ({
      csEnabled,
      csParams: parseSchemaParametersToDTO(csParams),
      id: key,
      s2sEnabled,
      s2sParams: parseSchemaParametersToDTO(s2sParams),
    }),
  )
