import { useState } from 'react'
import { generatePath, Navigate, useParams } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import Tabs, { getPage, Tab } from 'components/Tabs'
import {
  PrebidModulesContext,
  PrebidModulesContextType,
} from 'features/globalSetup/contexts/PrebidModulesContext'
import { useGetWorkspacePrebidModule } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModule'
import { PrebidModule } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModule } from 'features/inventory/domain/types/domainPrebidModule'
import { ROUTES } from 'routes'
import { ModulesCommonTabs } from '../../types'
import { useTabs } from './hooks/useTabs'
import { UrlParams } from './types'

export const GeneralEdit = (): JSX.Element => {
  const { id: urlWorkspacePrebidModuleId, tab: urlTab, workspaceId } = useParams<UrlParams>()
  const { workspacePrebidModule } = useGetWorkspacePrebidModule({ id: urlWorkspacePrebidModuleId })
  const tabs: Tab<ModulesCommonTabs>[] = useTabs()
  const [inventoryConnectionDirtyRows, setInventoryConnectionDirtyRows] = useState<
    DomainPrebidModule['id'][]
  >([])

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-modules-general-form-edit',
  })

  if (!workspaceId || !urlWorkspacePrebidModuleId) {
    return <Navigate to={ROUTES.BASE.NOT_FOUND} />
  } else if (!workspacePrebidModule) {
    return <Loading />
  }

  const contextProps: PrebidModulesContextType = {
    inventoryConnectionDirtyRows,
    setInventoryConnectionDirtyRows,
    workspacePrebidModule,
    ...commonContextProps,
  }

  const name: PrebidModule['name'] = workspacePrebidModule.prebidModule.name

  return (
    <PrebidModulesContext.Provider value={contextProps}>
      <PrebidModulesContext.Consumer>
        {(ctxProps: PrebidModulesContextType): JSX.Element => (
          <PageHeader
            actions={<SaveButton {...ctxProps} />}
            backTo={generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.GENERAL.LIST, { workspaceId })}
            title={name}
          />
        )}
      </PrebidModulesContext.Consumer>

      <Tabs
        path={ROUTES.GLOBAL_SETUP.PREBID.MODULES.GENERAL.EDIT}
        tabs={tabs}
      />

      {getPage<ModulesCommonTabs>(tabs, urlTab, 'general')}
    </PrebidModulesContext.Provider>
  )
}
