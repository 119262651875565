import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  FormControl,
  FormHelperText,
  Stack,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { HTMLAttributes } from 'react'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { SelectOption } from 'components/Select'
import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { SelectCheckobxesProps } from './types'

export const SelectCheckboxes = <T extends FieldValues, Data>({
  control,
  disabled,
  disabledFieldTooltip,
  name,
  options,
  tooltip: tooltipProps,
  ...textFieldProps
}: SelectCheckobxesProps<T, Data>): JSX.Element => {
  const calculatedWidth: number = inputWidth[textFieldProps.width ?? 'md']
  const containerSxProps: SxProps<Theme> = { minWidth: calculatedWidth }
  const inputSxProps: SxProps<Theme> = { ...containerSxProps, width: calculatedWidth }

  const icon: JSX.Element = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon: JSX.Element = <CheckBoxIcon fontSize='small' />

  const values: string[] = options.map((options: SelectOption<Data>): string => options.value)

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          error={Boolean(error)}
          sx={containerSxProps}
        >
          <Stack
            direction='row'
            sx={containerSxProps}
          >
            <Autocomplete
              {...field}
              disableClearable
              disableCloseOnSelect
              disabled={disabled}
              multiple
              onChange={(_, values: string[]): void => field.onChange(values)}
              options={values}
              renderInput={(props: AutocompleteRenderInputParams): JSX.Element => {
                const Field = (
                  <TextField
                    {...props}
                    {...textFieldProps}
                    sx={inputSxProps}
                  />
                )

                return disabledFieldTooltip && disabled ? (
                  <Tooltip
                    {...disabledFieldTooltip}
                    placement='top'
                  >
                    {Field}
                  </Tooltip>
                ) : (
                  Field
                )
              }}
              renderOption={(
                props: HTMLAttributes<HTMLLIElement>,
                option: string,
                { selected }: AutocompleteRenderOptionState,
              ): JSX.Element => (
                <li
                  {...props}
                  key={option}
                >
                  <Checkbox
                    checked={selected}
                    checkedIcon={checkedIcon}
                    icon={icon}
                  />
                  {option}
                </li>
              )}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}
          </Stack>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
