import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useAbility } from 'providers/casl'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { toDTO } from './api/mapper'
import { UPDATE_DOMAIN } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateDomain } from './types'

export const useUpdateDomain = (): UseUpdateDomain => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditDomainContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdateDomain] = useMutation(UPDATE_DOMAIN(ability), {
    onCompleted: ({ updateDomain: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
    refetchQueries: ['domain'],
  })

  const updateDomain = (data: EditSchema) => {
    setLoading(true)

    return mutateUpdateDomain({
      variables: {
        input: toDTO(data, ability),
      },
    })
  }

  return { errors, updateDomain }
}
