import { FieldErrors } from 'react-hook-form'

import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'

import 'providers/graphql'

import { AttributeErrors, attributesErrorsFromDTOtoSchema } from 'features/globalSetup/utils/error'
import { AdUnitBidderError } from './types'

export const parsedAdUnitBiddersErrors = (errors: AdUnitBidderError[]): FieldErrors<Schema> => {
  const { attributesErrors } = attributesErrorsFromDTOtoSchema(
    errors.map(
      (err: AdUnitBidderError): AttributeErrors => ({
        attributeId: err.adUnitBidderId,
        errors: err.errors,
      }),
    ),
  )

  return {
    adUnitBidders: attributesErrors,
  }
}
