import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { SelectOption } from 'components/Select'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { SupplyChainSlice } from '../../../types'
import { fromDTO, GET_SUPPPLY_CHAINS, SupplyChainSliceDTO } from './api'
import { UseGetSupplyChainsForSelect } from './types'

export const useGetSupplyChainsForSelect = (): UseGetSupplyChainsForSelect => {
  const { workspaceId } = useWorkspaceParam()
  const [options, setOptions] = useState<SelectOption<SupplyChainSlice>[]>([])

  useQuery(GET_SUPPPLY_CHAINS, {
    onCompleted: ({ supplyChains: { nodes } }): void => {
      if (nodes && nodes.length > 0) {
        setOptions(nodes.map((supplyChain: SupplyChainSliceDTO) => fromDTO(supplyChain)))
      }
    },

    variables: { workspaceId },
  })

  return options
}
