import { SelectOption } from 'components/Select'
import { LINE_ITEM_PRIORITIES } from './constants'

export const useLineItemPriorities = (): SelectOption<number[]>[] =>
  LINE_ITEM_PRIORITIES.map(
    (priority: number): SelectOption<number[]> => ({
      label: priority.toString(),
      value: priority.toString(),
    }),
  )
