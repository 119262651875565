import { AdUnitParametersDTO } from 'features/globalSetup/api/types/adUnitBidderDTO'
import { AdUnitParameter, AdUnitParameters } from 'features/globalSetup/types/adUnitBidder'
import { DomainBidder, DomainBidderParameter } from 'features/globalSetup/types/domainBidder'
import { parsePrimitiveValue } from 'features/globalSetup/utils/parameter'

export const mergeDomainBidderSchema = (
  adUnitParams: AdUnitParametersDTO,
  domainParamsSchema: DomainBidder['csParamsSchema'] | DomainBidder['s2sParamsSchema'],
): AdUnitParameters =>
  Object.fromEntries(
    domainParamsSchema.map(
      (schema: DomainBidderParameter): [keyof AdUnitParameters, AdUnitParameter] => {
        const { name, type } = schema

        return [
          name,
          {
            ...schema,
            value: parsePrimitiveValue(adUnitParams[name], type),
          },
        ]
      },
    ),
  )
