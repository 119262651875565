import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl'
import { AD_UNIT_BIDDER_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_AD_UNIT_BIDDERS = (ability: Ability): TypedDocumentNode<Data, Variables> => gql`
  ${AD_UNIT_BIDDER_FRAGMENT(ability)}

  query getAdUnitBidders(
    $workspaceBidderId: ID
    $domainId: ID
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: AdUnitBidderSortInput
  ) {
    adUnitBidders(
      workspaceBidderId: $workspaceBidderId
      domainId: $domainId
      first: $first
      last: $last
      terms: $terms
      sort: $sort
    ) {
      nodes {
        ...AdUnitBidderFragment
      }

      totalCount
    }
  }
`
