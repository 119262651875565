import { FieldErrors } from 'react-hook-form'

import { Schema } from 'features/globalSetup/forms/Prebid/Modules/InventoryConnectionForm/schema'
import { AttributeErrors, attributesErrorsFromDTOtoSchema } from 'features/globalSetup/utils/error'
import { DomainPrebidModuleAttributesErrors } from './types'

export const attributesErrorsToSchema = (
  errors: DomainPrebidModuleAttributesErrors[],
): FieldErrors<Schema> => {
  const { attributesErrors } = attributesErrorsFromDTOtoSchema(
    errors.map(
      (err: DomainPrebidModuleAttributesErrors): AttributeErrors => ({
        attributeId: err.domainPrebidModuleId,
        errors: err.errors,
      }),
    ),
  )

  return {
    domainPrebidModules: attributesErrors,
  }
}
