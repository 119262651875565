import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_PERFORMANCE_REPORT } from './api/query'
import { Data, Variables } from './api/types'
import { PerformanceReport, UseGetPerformanceReport, UseGetPerformanceReportProps } from './types'

export const useGetPerformanceReport = ({
  workspaceId: id,
}: UseGetPerformanceReportProps): UseGetPerformanceReport => {
  const [performanceReport, setPerformanceReport] = useState<PerformanceReport | undefined>()

  const { loading } = useQuery<Data, Variables>(GET_PERFORMANCE_REPORT, {
    onCompleted: ({ node: performanceReport }) => {
      if (performanceReport) {
        setPerformanceReport(fromDTO(performanceReport))
      }
    },
    variables: { id },
  })

  return { loading, performanceReport }
}
