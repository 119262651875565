import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { CommonSchema } from 'features/globalSetup/forms/Prebid/Modules/schemas/commonSchema'
import { parseSchemaParametersToDTO } from 'features/globalSetup/utils/parameter'

type From = CommonSchema
type To = Record<string, unknown>

export const commonSchemaParametersToDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const params = _.omit(data, ['accountType', 'prebidModuleId'])

    return parseSchemaParametersToDTO(params)
  })
