import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleAccount } from 'features/globalSetup/types/prebidModule'
import { useAbility } from 'providers/casl'
import { InventoryConnectionContext } from '../../../contexts/InventoryConnectionContext'
import { Schema } from '../../schema'
import { Data, toDTO, UPDATE_DOMAIN_PREBID_MODULES } from './api'
import { attributesErrorsToSchema } from './api/errors/mapper'
import { UseUpdatePrebidModuleDomains } from './types'

export const useUpdatePrebidModuleDomains = (): UseUpdatePrebidModuleDomains => {
  const ability = useAbility()
  const { moduleType } = useContext(InventoryConnectionContext)
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit.inventoryConnection.form`,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { inventoryConnectionDirtyRows, setLoading, workspacePrebidModule } =
    useContext(PrebidModulesContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const prebidModuleAccount: PrebidModuleAccount | undefined =
    workspacePrebidModule?.prebidModule?.account
  const canUpdateParamsByAccountType: boolean = prebidModuleAccount
    ? ability.can(
        'update',
        `GlobalSetupField-prebid-modules-inventory-connection-params-${prebidModuleAccount}`,
      )
    : false
  const canUpdateParams: boolean = moduleType !== 'general' && canUpdateParamsByAccountType

  const [mutate, { loading }] = useMutation(UPDATE_DOMAIN_PREBID_MODULES, {
    onCompleted: ({ updateDomainPrebidModules: { attributesErrors } }: Data): void => {
      if (!_.isEmpty(attributesErrors)) {
        if (attributesErrors && attributesErrors.length > 0) {
          setErrors(attributesErrorsToSchema(attributesErrors))
        }
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('success'), { variant: 'success' })
      }
    },
    refetchQueries: ['getDomainPrebidModules'],
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const update = (data: Schema): void => {
    mutate({
      variables: {
        input: toDTO({
          canUpdateParams,
          data,
          dirtyRowsIds: inventoryConnectionDirtyRows,
        }),
      },
    })
  }

  return { errors, update }
}
