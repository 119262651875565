import { FetchResult, useMutation } from '@apollo/client'
import _ from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { Schema } from '../../form/schema'
import { useExistingWorkspaceBidder } from '../useExistingWorkspaceBidder'
import { parsedAdUnitBiddersErrors } from './api/errors/mapper'
import { toDTO } from './api/mapper'
import { UPDATE_DOMAIN_BIDDER } from './api/mutation'
import { Data } from './api/types'
import { UseUpdateDomainBidder } from './types'

export const useUpdateDomainBidder = (): UseUpdateDomainBidder => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.update',
  })
  const { inventoryConnectionDirtyRows, setLoading } = useContext(WorkspaceBidderContext)
  const {
    workspaceBidder: { bidderCode },
  } = useExistingWorkspaceBidder()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate, { loading }] = useMutation(UPDATE_DOMAIN_BIDDER, {
    onCompleted: ({ updateDomainBidder: { errors } }: Data): void => {
      if (!_.isEmpty(errors)) {
        if (errors.adUnitBidders && errors.adUnitBidders.length > 0) {
          setErrors(parsedAdUnitBiddersErrors(errors.adUnitBidders))
        }
        enqueueSnackbar(t('error', { bidderCode }), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('success', { bidderCode }), { variant: 'success' })
      }
    },
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const update = (data: Schema): Promise<FetchResult<Data>> =>
    mutate({ variables: { input: toDTO(data, inventoryConnectionDirtyRows) } })

  return { errors, update }
}
