import { ColumnDef } from '@tanstack/react-table'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { InventoryConnectionContext } from 'features/globalSetup/forms/Prebid/Modules/contexts/InventoryConnectionContext'
import { PrebidModuleAccount } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModuleSchemaParameter } from 'features/inventory/domain/types/domainPrebidModule'
import { useAbility } from 'providers/casl'
import { DomainPrebidModuleSlice } from '../../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../../schema'
import { createParamsColumns } from './createParamsColumns'

export const useColumns = ({
  paramsSchema,
  prebidModuleAccount,
}: {
  paramsSchema: DomainPrebidModuleSchemaParameter[]
  prebidModuleAccount?: PrebidModuleAccount
}): ColumnDef<DomainPrebidModuleSlice>[] => {
  const { moduleType } = useContext(InventoryConnectionContext)
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit.inventoryConnection.list.header`,
  })
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  return [
    {
      accessorKey: 'domain.name',
      header: t('domainName'),
    },
    {
      accessorKey: 'enabled',
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <Switch<Schema>
          control={control}
          disabled={ability.cannot('update', `GlobalSetupFeature-${moduleType}`)}
          name={`domainPrebidModules.${id}.enabled`}
          value={getValue()}
        />
      ),
      header: t('enabled'),
      meta: { nonClickable: true },
    },
    ...createParamsColumns({ paramsSchema, prebidModuleAccount }),
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
  ]
}
