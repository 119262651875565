import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmployeeBox } from 'components/Box'
import { BoxForm, Switch, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { URL } from './constants'
import { Schema } from './schema'

export const WorkspaceForm = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { control } = useFormContext<Schema>()

  return (
    <>
      <Paper>
        <SectionHeader
          bottomSpacing
          subtitle={t('general.workspace.header.description')}
          title={t('general.workspace.header.title')}
        />

        <BoxForm>
          <TextField
            control={control}
            disabled={!ability.can('update', 'GlobalSetupField-workspace')}
            label={t('general.workspace.form.name')}
            name='name'
          />

          {ability.can('read', 'GlobalSetupField-priceGenius') && (
            <EmployeeBox>
              <Switch
                control={control}
                disabled={!ability.can('update', 'GlobalSetupField-priceGenius')}
                label={t('general.workspace.form.priceGeniusAlwaysOn.label')}
                labelPlacement='start'
                name='priceGeniusAlwaysOn'
                tooltip={{
                  content: t('general.workspace.form.priceGeniusAlwaysOn.tooltip'),
                  link: {
                    title: t('common:learnMore'),
                    url: URL.PRICE_GENIUS_ALWAYS_ON,
                  },
                }}
              />
            </EmployeeBox>
          )}
        </BoxForm>
      </Paper>
    </>
  )
}
