import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { WorkspaceProductDTO } from 'features/product/api/types/workspaceProductDTO'
import { WorkspaceProduct } from 'features/product/types/workspaceProduct'
import { workspaceProductFromDTO } from './api/mapper'
import { GET_WORKSPACE_PRODUCTS } from './api/query'
import { Data } from './api/types'
import { UseGetWorkspaceProducts } from './types'

export const useGetWorkspaceProducts = (): UseGetWorkspaceProducts => {
  const [products, setProducts] = useState<WorkspaceProduct[]>([])

  const { loading } = useQuery<Data>(GET_WORKSPACE_PRODUCTS, {
    onCompleted: ({ workspaceProducts }) => {
      setProducts(
        workspaceProducts.map(
          (product: WorkspaceProductDTO): WorkspaceProduct => workspaceProductFromDTO(product),
        ),
      )
    },
  })

  return { loading, products }
}
