import { DefaultOptions, ErrorPolicy, FetchPolicy } from '@apollo/client'

type Policies = {
  CACHE: Extract<FetchPolicy, 'no-cache'>
  ERROR: Extract<ErrorPolicy, 'none'>
}

const POLICIES: Policies = {
  CACHE: 'no-cache',
  ERROR: 'none',
} as const

const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: POLICIES.ERROR,
    fetchPolicy: POLICIES.CACHE,
  },
  query: {
    errorPolicy: POLICIES.ERROR,
    fetchPolicy: POLICIES.CACHE,
  },
  watchQuery: {
    errorPolicy: POLICIES.ERROR,
    fetchPolicy: POLICIES.CACHE,
    initialFetchPolicy: POLICIES.CACHE,
    nextFetchPolicy: POLICIES.CACHE,
  },
}

export default defaultOptions
