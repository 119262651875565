import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_PREBID_MODULE_ATTRIBUTES_ERRORS_FRAGMENT } from './errors/fragment'
import { Data, Variables } from './types'

export const UPDATE_DOMAIN_PREBID_MODULES: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${DOMAIN_PREBID_MODULE_ATTRIBUTES_ERRORS_FRAGMENT}

  mutation updateDomainPrebidModules($input: UpdateDomainPrebidModulesInput!) {
    updateDomainPrebidModules(input: $input) {
      attributesErrors {
        ...DomainPrebidModuleAttributesErrorsFragment
      }
    }
  }
`
