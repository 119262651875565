import { useContext, useEffect } from 'react'
import { UseFieldArrayPrepend } from 'react-hook-form'

import { ViewportPageContext } from 'features/globalSetup/features/viewport/contexts/ViewportPageContext'
import {
  defaultViewportSchema,
  Schema,
} from 'features/globalSetup/features/viewport/forms/ViewportForm/schema'

type Props = {
  prepend: UseFieldArrayPrepend<Schema>
}
export const usePrependOnAddNewButton = ({ prepend }: Props): void => {
  const { setOnAddNew } = useContext(ViewportPageContext)

  useEffect(() => {
    if (setOnAddNew !== undefined) {
      setOnAddNew(() => (): void => {
        prepend(defaultViewportSchema)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
