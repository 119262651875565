import { ColumnDef } from '@tanstack/react-table'
import { FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch, TagsField } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { PREDEFINED_AD_UNIT_SIZES } from 'features/globalSetup/features/viewport'
import { useAbility } from 'providers/casl'
import { CommonSchema } from '../../../../schemas/commonSchema'
import { ActionsCell } from './components/ActionsCell'
import { NameCell } from './components/NameCell'
import { ViewportWidthCell } from './components/ViewportWidthCell'

type Props = {
  remove: UseFieldArrayRemove
}

export const useColumns = ({ remove }: Props): ColumnDef<FieldArrayWithId<CommonSchema>>[] => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.viewportList.header',
  })
  const { control } = useFormContext<CommonSchema>()
  const ability = useAbility()
  const disabled = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return [
    {
      accessorKey: 'viewport.name',
      cell: ctx => <NameCell {...ctx} />,
      header: t('name.label'),
    },
    {
      accessorKey: 'viewport.minimumWidth',
      cell: ctx => <ViewportWidthCell {...ctx} />,
      header: t('viewportWidth'),
    },
    {
      accessorKey: 'bannerEnabled',
      cell: ({ row: { index } }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${index}.bannerEnabled`}
        />
      ),
      header: t('bannerEnabled'),
    },
    {
      accessorKey: 'bannerSizes',
      cell: ({ cell: { getValue }, row: { index } }) => (
        <TagsField
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${index}.bannerSizes`}
          options={PREDEFINED_AD_UNIT_SIZES}
          value={getValue()}
        />
      ),
      header: t('bannerSizes'),
    },
    {
      accessorKey: 'outstreamEnabled',
      cell: ({ row: { index } }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${index}.outstreamEnabled`}
        />
      ),
      header: t('outstreamEnabled'),
    },
    {
      accessorKey: 'playerSize',
      cell: ({ cell: { getValue }, row: { index } }) => (
        <TagsField
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${index}.playerSize`}
          options={PREDEFINED_AD_UNIT_SIZES}
          value={getValue()}
        />
      ),
      header: t('playerSize'),
    },
    {
      accessorKey: 'nativeEnabled',
      cell: ({ row: { index } }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${index}.nativeEnabled`}
        />
      ),
      header: t('nativeEnabled'),
    },
    {
      accessorKey: 'updatedAt',
      cell: ctx => <LastModifiedCell {...ctx} />,
      header: t('updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: ({ row: { index } }) => <ActionsCell removeProps={{ index, remove }} />,
      header: t('actions'),
    },
  ]
}
