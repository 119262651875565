export const URL = {
  GOOGLE_REFRESH_POLICY: 'https://support.google.com/admanager/answer/6286179',
  KNOWLEDGE_BASE: {
    BASIC_SETTINGS: 'https://knowledge-base.yieldbird.com/refresher/basic-settings',
    LINE_ITEM_FIELDS: 'https://knowledge-base.yieldbird.com/yieldbird-platform/gam-api-connection',
    TARGETING:
      'https://knowledge-base.yieldbird.com/refresher/targeting-criteria-for-refreshed-inventory',
  },
} as const

export const SHOW_HINT_BELOW = {
  AD_THRESHOLD: 50,
  REFRESH_INTERVAL: 30,
}
