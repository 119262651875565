import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Row } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Dialog from 'components/Dialog'
import { Switch } from 'components/Switch'
import { updateUser, useAuthUser } from 'features/user'
import { Workspace } from 'features/workspace'
import { mapGraphQLErrors } from 'providers/graphql'
import { WorkspaceForTable } from '../../types'
import { isWorkspaceRow } from '../utils'
import { UPDATE_WORKSPACE_STATUS } from './mutation'
import { Data, WorkspaceSliceDTO } from './types'

export const StatusSwitch = ({ depth, original: { active, id, name } }: Row<WorkspaceForTable>) => {
  const { t } = useTranslation('features/workspace')
  const { user } = useAuthUser()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [checked, setChecked] = useState<boolean>(active)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [update, { loading }] = useMutation(UPDATE_WORKSPACE_STATUS, {
    onCompleted: ({ updateWorkspaceStatus: { errors, workspace } }: Data): void => {
      setIsDialogOpen(false)

      if (errors && errors.length > 0) {
        const err = mapGraphQLErrors<FieldErrors<WorkspaceSliceDTO>>(errors)
        const msg = err.active?.message

        if (msg !== undefined) {
          enqueueSnackbar(msg as string, { variant: 'error' })
        }
      } else if (workspace !== null) {
        setChecked(workspace.active)
        enqueueSnackbar(t('statusSwitch.successUpdate'), { variant: 'success' })

        const updatedWorkspaces = user.workspaces.map((w: Workspace) =>
          w.id === id
            ? {
                ...w,
                active: !checked,
              }
            : w,
        )
        dispatch(updateUser({ workspaces: updatedWorkspaces }))
      }
    },
  })

  const handleUpdate = () =>
    void update({
      variables: {
        input: {
          active: !checked,
          id,
        },
      },
    })

  const selector = checked ? 'deactivate' : 'activate'

  const DialogAction: JSX.Element = (
    <Button
      disabled={loading}
      onClick={handleUpdate}
      variant='contained'
    >
      {t(`statusSwitch.dialog.${selector}.action`)}
    </Button>
  )

  return isWorkspaceRow(depth) ? (
    <>
      <Switch
        checked={checked}
        name={`workspace-${id}-active`}
        onClick={() => setIsDialogOpen(true)}
      />

      <Dialog
        actions={DialogAction}
        content={t(`statusSwitch.dialog.${selector}.content`, { name })}
        isOpen={isDialogOpen}
        setOpen={setIsDialogOpen}
        title={t(`statusSwitch.dialog.${selector}.title`)}
      />
    </>
  ) : (
    <></>
  )
}
