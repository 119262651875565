import { ColumnDef } from '@tanstack/react-table'
import { useContext, useEffect, useState } from 'react'
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form'

import { Table } from 'components/Table'
import { useSort } from 'components/Table/Sort'
import { useColumns } from 'features/globalSetup/features/viewport/components/ViewportTable/useColumns'
import {
  useGetViewports,
  ViewportSlice,
} from 'features/globalSetup/features/viewport/hooks/useGetViewports'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ViewportPageContext } from '../../contexts/ViewportPageContext'
import { Schema } from '../../forms/ViewportForm/schema'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { usePrependOnAddNewButton } from './hooks/usePrependOnAddNewButton'
import { ViewportSortBy } from './types'

export const ViewportTable = () => {
  const { workspaceId } = useWorkspaceParam()
  const { filters } = useContext(ViewportPageContext)
  const [loadingFields, setLoadingFields] = useState<boolean>(false)

  const { props: sortProps, sort } = useSort<FieldArrayWithId<ViewportSlice>, ViewportSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { viewports } = useGetViewports({
    filters,
    sort,
    workspaceId,
  })

  const { control, reset } = useFormContext<Schema>()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'viewports',
  })
  usePrependOnAddNewButton({ prepend })

  const columns: ColumnDef<FieldArrayWithId<ViewportSlice>>[] = useColumns({ remove })

  useEffect(() => {
    setLoadingFields(true)
    reset({ viewports })
  }, [viewports]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingFields(false)
  }, [fields])

  return (
    <Table<FieldArrayWithId<ViewportSlice>>
      columns={columns}
      data={fields}
      loading={loadingFields}
      sort={sortProps}
    />
  )
}
