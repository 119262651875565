import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../schema'
import { Data, toDTO, UPDATE_FINANCE_REPORT_URL } from './api'
import { UseUpdateFinanceReportUrl } from './types'

export const useUpdateFinanceReportUrl = (): UseUpdateFinanceReportUrl => {
  const { t } = useTranslation('features/finance')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutateUpdateWorkspaceProduct] = useMutation(UPDATE_FINANCE_REPORT_URL, {
    onCompleted: ({ updateWorkspace: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), {
          variant: 'error',
        })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), {
          variant: 'success',
        })
      }
    },
    onError: (): void => setLoading(false),
    refetchQueries: ['getFinanceReportUrl'],
  })

  const update = (data: Schema) => {
    setLoading(true)

    return mutateUpdateWorkspaceProduct({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return {
    errors,
    loading,
    update,
  }
}
