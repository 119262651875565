import { RouteObject } from 'react-router-dom'

import GeneralPage from 'features/globalSetup/pages/GeneralPage'
import { WorkspaceUserAddPage } from 'features/globalSetup/pages/WorkspaceUserAddPage'
import { WorkspaceUserEditPage } from 'features/globalSetup/pages/WorkspaceUserEditPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from 'routes'

export const generalRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-general'
        page={<GeneralPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.GENERAL.ROOT,
  },
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-workspaceUser-add'
        page={<WorkspaceUserAddPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.GENERAL.USER.ADD,
  },
  {
    element: (
      <AuthorizedRoute
        access='GlobalSetupPage-workspaceUser-edit'
        page={<WorkspaceUserEditPage />}
      />
    ),
    path: ROUTES.GLOBAL_SETUP.GENERAL.USER.EDIT,
  },
]
