import { Delete, MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { Props } from './types'

export const ActionsCell = ({ removeProps: { index, remove } }: Props): JSX.Element => {
  const ability = useAbility()
  const { t: tC } = useTranslation('common')
  const { anchor, close, isOpen, open } = useMenu()

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={(): void => {
        remove(index)
        close()
      }}
      text={tC('form.action.delete')}
    />
  )

  const MenuElements = (): JSX.Element => (
    <>{ability.can('delete', 'GlobalSetupFeature-viewport') && [<DeleteItem key='delete' />]}</>
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuElements />
      </Menu>
    </>
  )
}
