import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainRefresherDTO } from '../types/domainRefresherDTO'

export const DOMAIN_REFRESHER_FRAGMENT: TypedDocumentNode<DomainRefresherDTO> = gql`
  fragment DomainRefresherFragment on DomainRefresher {
    adThreshold
    advertiserIds
    createdAt
    domainId
    id
    lineItemIds
    lineItemName
    lineItemPriority
    matchType
    monitorUserScrollEnabled
    orderIds
    refresherEnabled
    refreshInterval
    targetingEnabled
    updatedAt
  }
`
