import { Divider } from '@mui/material'

import { Form as FormProvider } from 'components/Form'
import Paper from 'components/Paper'
import { AdminOptionFormHeader } from 'features/performance'
import { DOCUMENTATION_URL } from './constants'
import { Form } from './Form'
import { defaults, Schema, schema } from './schema'
import { FormContainerProps } from './types'
import { useUpdateFinanceReportUrl } from './useUpdateFinanceReportUrl'

export const FormContainer = ({ data }: FormContainerProps): JSX.Element => {
  const { errors, loading, update } = useUpdateFinanceReportUrl()

  return (
    <FormProvider<Schema>
      externalErrors={errors}
      id='performance-form'
      loading={loading}
      onSubmit={update}
      schema={schema}
      schemaDefaults={defaults(data)}
    >
      {() => (
        <Paper variant='cream'>
          <AdminOptionFormHeader
            documentationUrl={DOCUMENTATION_URL}
            loading={loading}
          />

          <Divider sx={{ marginBottom: 3 }} />

          <Form />
        </Paper>
      )}
    </FormProvider>
  )
}
