import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useDeleteWorkspacePrebidModule } from 'features/globalSetup/pages/Prebid/Modules/hooks/useDeleteWorkspacePrebidModule'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ id, prebidModule }: ActionsCellProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.common.list',
  })
  const { t: tc } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, close, isOpen, open } = useMenu()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { destroy, loading } = useDeleteWorkspacePrebidModule({ prebidModule })

  const handleDelete = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={() =>
        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.USER_ID.EDIT, {
            id,
            tab: 'general',
            workspaceId,
          }),
        )
      }
      text={tc('form.action.edit')}
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={handleDelete}
      text={tc('form.action.delete')}
    />
  )

  const MenuItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [<EditItem key='edit' />]

    if (ability.can('delete', 'GlobalSetupFeature-userId')) {
      items = items.concat([
        <Divider
          key='divider'
          sx={{ my: 1 }}
        />,
        <DeleteItem key='delete' />,
      ])
    }

    return items
  }

  return MenuItems().length ? (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuItems />
      </Menu>

      <DeleteDialog
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        elementName={prebidModule.name}
        i18nResource={t}
        loading={loading}
        onDelete={() => void destroy(id)}
      />
    </>
  ) : (
    <></>
  )
}
