import { parsePrimitiveValue } from 'features/globalSetup/utils/parameter'
import {
  DomainPrebidModuleParamsDTO,
  DomainPrebidModuleParamsSchemaDTO,
} from 'features/inventory/domain/api/types/domainPrebidModuleDTO'
import {
  DomainPrebidModuleParameter,
  DomainPrebidModuleParameters,
  DomainPrebidModuleSchemaParameter,
} from 'features/inventory/domain/types/domainPrebidModule'
import { isPrimitive } from 'utils/primitive'

export const paramsSchemaFromDTO = (
  paramsSchema: DomainPrebidModuleParamsSchemaDTO[],
): DomainPrebidModuleSchemaParameter[] =>
  paramsSchema.map(
    (paramDTO: DomainPrebidModuleParamsSchemaDTO): DomainPrebidModuleSchemaParameter => {
      const { tooltip, type } = paramDTO

      if (!isPrimitive(type)) {
        throw Error('Type of input does not match to primitive types')
      }

      return {
        ...paramDTO,
        tooltip: tooltip ? { content: tooltip } : undefined,
        type,
      }
    },
  )

export const mergeParamsWithSchema = (
  paramsDTO: DomainPrebidModuleParamsDTO,
  paramsSchema: DomainPrebidModuleSchemaParameter[],
): DomainPrebidModuleParameters =>
  Object.fromEntries(
    paramsSchema.map(
      (
        schema: DomainPrebidModuleSchemaParameter,
      ): [keyof DomainPrebidModuleParameters, DomainPrebidModuleParameter] => {
        const { name, type } = schema

        return [
          name,
          {
            ...schema,
            value: parsePrimitiveValue(paramsDTO[name], type),
          },
        ]
      },
    ),
  )
