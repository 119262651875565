import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { fromDTO, GET_SUPPLY_CHAINS } from './api'
import { SupplyChainSliceDTO } from './api/types'
import { SupplyChainForTable, UseGetSupplyChains, UseGetSupplyChainsProps } from './types'

export const useGetSupplyChains = ({
  filters,
  first,
  last,
  sort,
}: UseGetSupplyChainsProps): UseGetSupplyChains => {
  const { workspaceId } = useWorkspaceParam()
  const [supplyChains, setSupplyChains] = useState<SupplyChainForTable[]>([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_SUPPLY_CHAINS, {
    onCompleted: ({ supplyChains: { nodes: supplyChains, totalCount: count } }): void => {
      if (supplyChains) {
        setSupplyChains(
          supplyChains.map(
            (supplyChain: SupplyChainSliceDTO): SupplyChainForTable => fromDTO(supplyChain),
          ),
        )
      }

      setCount(count)
    },

    variables: {
      first,
      last,
      sort,
      terms: filters,
      workspaceId,
    },
  })

  return {
    count,
    loading,
    supplyChains,
  }
}
