import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitSizeDTO } from 'features/globalSetup/features/viewport/types/adUnitSizeDTO'
import { adUnitSizeFromDTO } from 'features/globalSetup/features/viewport/utils'
import { ViewportSlice } from '../types'
import { ViewportSliceDTO } from './types'

type From = ViewportSliceDTO
type To = ViewportSlice

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const { bannerSizes, id, playerSize, updatedAt } = data

    return {
      ...data,
      bannerSizes: bannerSizes.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
      playerSize: playerSize.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
      updatedAt: new Date(updatedAt),
      viewportId: id,
    }
  })
