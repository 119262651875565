import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditPageContext } from 'features/inventory/page/contexts/AddEditPageContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { EditSchema } from '../../schemas/editSchema'
import { UPDATE_PAGE } from './api/mutation'
import { Data, Variables } from './api/types'
import { UseUpdatePage } from './types'

export const useUpdatePage = (): UseUpdatePage => {
  const { t } = useTranslation('features/page')
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditPageContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutateUpdatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: ({ updatePage: { errors } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('form.error.update'), { variant: 'error' })
      } else {
        setErrors({})
        enqueueSnackbar(t('form.success.update'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const mapper = (data: EditSchema): Variables => {
    const { matchType, path } = data

    return {
      ..._.omit(data, 'domainId'),
      matchType: matchType || null,
      path: path || null,
    }
  }

  const updatePage = (data: EditSchema) => {
    setLoading(true)

    return mutateUpdatePage({
      variables: {
        input: mapper(data),
      },
    })
  }

  return { errors, updatePage }
}
