import components from 'components/locales.json'
import finance from 'features/finance/locales.json'
import globalSetupAmazon from 'features/globalSetup/features/amazon/locales.json'
import globalSetupViewport from 'features/globalSetup/features/viewport/locales.json'
import globalSetup from 'features/globalSetup/locales.json'
import adUnit from 'features/inventory/ad-unit/locales.json'
import domain from 'features/inventory/domain/locales.json'
import page from 'features/inventory/page/locales.json'
import layout from 'features/layout/locales.json'
import performance from 'features/performance/locales.json'
import product from 'features/product/locales.json'
import tag from 'features/tag/locales.json'
import user from 'features/user/locales.json'
import workspace from 'features/workspace/locales.json'
import common from 'locales/common.json'
import graphql from 'providers/graphql/locales.json'
import router from 'providers/router/locales.json'

export type I18nResource = keyof typeof RESOURCES.en

export const RESOURCES = {
  en: {
    common,
    components,
    'features/adUnit': adUnit,
    'features/domain': domain,
    'features/finance': finance,
    'features/globalSetup': globalSetup,
    'features/globalSetup/amazon': globalSetupAmazon,
    'features/globalSetup/viewport': globalSetupViewport,
    'features/layout': layout,
    'features/page': page,
    'features/performance': performance,
    'features/product': product,
    'features/tag': tag,
    'features/user': user,
    'features/workspace': workspace,
    'providers/graphql': graphql,
    'providers/router': router,
  },
} as const
