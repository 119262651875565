import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import {
  domainPrebidModulesFromDTO,
  DomainPrebidModuleSliceDTO,
  GET_DOMAIN_PREBID_MODULES,
} from './api'
import { sortToDTO } from './api/mapper'
import {
  DomainPrebidModuleSlice,
  UseGetPrebidModuleDomains,
  UseGetPrebidModuleDomainsProps,
} from './types'

export const useGetPrebidModulesDomains = ({
  filters,
  first,
  last,
  moduleType,
  sort,
  workspacePrebidModuleId,
}: UseGetPrebidModuleDomainsProps): UseGetPrebidModuleDomains => {
  const [prebidModuleDomains, setPrebidModuleDomains] = useState<DomainPrebidModuleSlice[]>([])
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    if (workspacePrebidModuleId !== undefined) {
      query({
        variables: {
          first,
          last,
          moduleType,
          sort: sortToDTO(sort),
          terms: filters,
          workspacePrebidModuleId,
        },
      })
    }
  }, [workspacePrebidModuleId, moduleType, filters, first, last, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query, { loading }] = useLazyQuery(GET_DOMAIN_PREBID_MODULES, {
    onCompleted: ({ domainPrebidModules: { nodes, totalCount: count } }): void => {
      if (nodes) {
        setPrebidModuleDomains(
          nodes.map(
            (domainPrebidModule: DomainPrebidModuleSliceDTO): DomainPrebidModuleSlice =>
              domainPrebidModulesFromDTO(domainPrebidModule),
          ),
        )
        setCount(count)
      }
    },
  })

  return {
    count,
    loading,
    prebidModuleDomains,
  }
}
