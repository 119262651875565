import Adagio from 'images/logos/prebidModule/Adagio.svg?react'
import Adquery from 'images/logos/prebidModule/Adquery QID.svg?react'
import AMX from 'images/logos/prebidModule/AMX ID.svg?react'
import ATS from 'images/logos/prebidModule/ATS Analytics.svg?react'
import Cee from 'images/logos/prebidModule/Cee ID.svg?react'
import Criteo from 'images/logos/prebidModule/Criteo ID.svg?react'
import Geoedge from 'images/logos/prebidModule/Geoedge.svg?react'
import GooglePairID from 'images/logos/prebidModule/Google PAIR ID.svg?react'
import Greenbids from 'images/logos/prebidModule/Greenbids.svg?react'
import Id5 from 'images/logos/prebidModule/Id5.svg?react'
import Just from 'images/logos/prebidModule/Just ID.svg?react'
import Live from 'images/logos/prebidModule/Live Intent Id.svg?react'
import Lotame from 'images/logos/prebidModule/Lotame Panorama Id.svg?react'
import Lupon from 'images/logos/prebidModule/Lupon.svg?react'
import Quantcast from 'images/logos/prebidModule/Quantcast ID.svg?react'
import Roxot from 'images/logos/prebidModule/Roxot.svg?react'
import Shared from 'images/logos/prebidModule/Shared Id.svg?react'
import Teads from 'images/logos/prebidModule/Teads ID.svg?react'
import Unified from 'images/logos/prebidModule/Unified Id.svg?react'
import Weborama from 'images/logos/prebidModule/Weborama.svg?react'
import { Logos } from 'types/logos'

export const prebidModuleLogos: Logos = {
  'Adagio RTD Provider': Adagio,
  'Adquery QID': Adquery,
  'AMX ID': AMX,
  'ATS Analytics': ATS,
  'Cee ID': Cee,
  'Criteo ID': Criteo,
  Geoedge: Geoedge,
  'Google PAIR ID': GooglePairID,
  'Greenbids Analytics': Greenbids,
  'Greenbids RTD Provider': Greenbids,
  Id5: Id5,
  'Just ID': Just,
  'Live Intent ID': Live,
  'Lotame Panorama ID': Lotame,
  'Lupon Media DAB+': Lupon,
  'Quantcast ID': Quantcast,
  'Roxot Analytics': Roxot,
  'Shared ID': Shared,
  'Teads ID': Teads,
  'Unified ID': Unified,
  Weborama: Weborama,
} as const
