import { User } from 'features/user'
import { Can } from '../Can'
import {
  onGlobalSetupAmazonUpdate,
  onGlobalSetupAmazonYieldbirdAccountStatus,
} from './features/globalSetup/amazon'
import { onViewport } from './features/globalSetup/viewport'
import { onPageAddAccess, onPageCreateUpdateDelete } from './features/page'

const onAdUnitUnfilledRecovery = (can: Can) => {
  can('update', 'AdUnitField-unfilledRecovery')
}

const onDomainOverridenByYieldbirdUpdate = (can: Can) => {
  can('update', 'DomainField-overriddenByYieldbird')
}

const onGlobalSetupPriceGenius = (can: Can) => {
  can('update', 'GlobalSetupField-priceGenius')
}

const onUserPageEmployee = (can: Can) => {
  can('access', 'UserPage-addEmployee')
  can('create', 'Employee')
  can('access', 'UserPage-editEmployee')
  can('update', 'Employee')
  can('delete', 'Employee')
}

const onUserPagePublisher = (can: Can) => {
  can('access', 'UserPage-addPublisher')
  can('create', 'Publisher')
  can('access', 'UserPage-editPublisher')
  can('update', 'Publisher')
  can('delete', 'Publisher')
}

const onWorkspace = (can: Can) => {
  can('delete', 'Workspace')
}

const onWorkspaceSelect = (can: Can) => {
  can('use', 'WorkspaceFeature-select')
}

export const onDomainAdvancedSettings = (can: Can) => {
  can('update', 'DomianFeature-advancedSettings')
}

export const onDomainBulkManagement = (can: Can) => {
  can('create', 'DomainFeature-bulkManagement')
}

export const onDomainCustomCode = (can: Can) => {
  can('create', 'DomianFeature-customCode')
  can('update', 'DomianFeature-customCode')
}

export const onAdUnitMediaTypes = (can: Can) => {
  can('create', 'AdUnitFeature-mediaTypes')
  can('update', 'AdUnitFeature-mediaTypes')
  can('delete', 'AdUnitFeature-mediaTypes')
}

export const onGlobalSetupPrebidDefaults = (can: Can) => {
  can('update', 'GlobalSetupFeature-prebidDefaults')
}

export const onGlobalSetupBiddersCreateUpdateDelete = (can: Can) => {
  can('create', 'GlobalSetupFeature-bidder')
  can('update', 'GlobalSetupFeature-bidder')
  can('delete', 'GlobalSetupFeature-bidder')
}

export const onGlobalSetupPrebidBiddersAdd = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-bidders-add')
}

export const onGlobalSetupBiddersOwn = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderCode')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderSid')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-own-priority')
  can('update', 'GlobalSetupField-prebid-bidders-own-consentMode')
}

export const onGlobalSetupBiddersThirdParty = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-supplyChain')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderCode')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderSid')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-priority')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-consentMode')
}

export const onGlobalSetupBiddersYieldbird = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-priority')
}

export const onGlobalSetupBiddersYieldbirdCpmConsentStatus = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-consentMode')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-status')
}

export const onGlobalSetupBiddersBidParamsCommon = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-priority')
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-cs')
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-s2s')
}

export const onGlobalSetupBiddersBidParamsOwn = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-own')
}

export const onGlobalSetupBiddersBidParamsYieldbird = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-yielbdird')
}

export const onGlobalSetupPrebidModule = (can: Can) => {
  can('update', 'GlobalSetupFeature-general')
  can('create', 'GlobalSetupFeature-userId')
  can('update', 'GlobalSetupFeature-userId')
  can('delete', 'GlobalSetupFeature-userId')
  can('create', 'GlobalSetupFeature-vendorSpecific')
  can('update', 'GlobalSetupFeature-vendorSpecific')
  can('delete', 'GlobalSetupFeature-vendorSpecific')
  can('create', 'GlobalSetupFeature-analytics')
  can('update', 'GlobalSetupFeature-analytics')
  can('delete', 'GlobalSetupFeature-analytics')
}

export const onGlobalSetupPrebidModulesGeneralParamsOwn = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-general-params-own')
}

export const onGlobalSetupPrebidModulesGeneralParamsYieldbird = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-general-params-yieldbird')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-inventory-connection-params-own')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-inventory-connection-params-yieldbird')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead = (can: Can) => {
  can('read', 'GlobalSetupField-prebid-modules-inventory-connection-params-yieldbird')
}

export const onGlobalSetupPrebidModuleStatus = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-status')
}

export const onGlobalSetupProtectionCUD = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-protection-add')
  can('create', 'GlobalSetupFeature-protection')
  can('update', 'GlobalSetupFeature-protection')
  can('delete', 'GlobalSetupFeature-protection')
}

export const onGlobalSetupWorkspaceUser = (can: Can) => {
  can('access', 'GlobalSetupPage-workspaceUser-add')
  can('access', 'GlobalSetupPage-workspaceUser-edit')
  can('create', 'WorkspaceUser')
  can('update', 'WorkspaceUser')
  can('delete', 'WorkspaceUser')
}

export const onGlobalSetupGoogleAdManagerConnectionUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-googleAdManagerConnection-connect')
  can('update', 'GlobalSetupFeature-googleAdManagerConnection-setupPrebidStack')
}

export const onGlobalSetupSupplyChainAddAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-supplyChain-add')
}

export const onGlobalSetupSupplyChainCreateUpdateDelete = (can: Can) => {
  can('create', 'GlobalSetupFeature-supplyChain')
  can('update', 'GlobalSetupFeature-supplyChain')
  can('delete', 'GlobalSetupFeature-supplyChain')
}

const onPerformanceDataSampling = (can: Can) => {
  can('read', 'Performance-dataSampling')
  can('update', 'Performance-dataSampling')
}

export const userByRole = (user: User, can: Can) => {
  switch (user.role) {
    case 'admin': {
      onAdUnitMediaTypes(can)
      onAdUnitUnfilledRecovery(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onDomainOverridenByYieldbirdUpdate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatus(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersBidParamsYieldbird(can)
      onGlobalSetupBiddersCreateUpdateDelete(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatus(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupPrebidModulesGeneralParamsOwn(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbird(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatus(can)
      onGlobalSetupPriceGenius(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainCreateUpdateDelete(can)
      onGlobalSetupWorkspaceUser(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSampling(can)
      onUserPageEmployee(can)
      onUserPagePublisher(can)
      onViewport(can)
      onWorkspace(can)
      break
    }

    case 'adops': {
      onAdUnitMediaTypes(can)
      onAdUnitUnfilledRecovery(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onDomainOverridenByYieldbirdUpdate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatus(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersBidParamsYieldbird(can)
      onGlobalSetupBiddersCreateUpdateDelete(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatus(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupPrebidModulesGeneralParamsOwn(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbird(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatus(can)
      onGlobalSetupPriceGenius(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainCreateUpdateDelete(can)
      onGlobalSetupWorkspaceUser(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSampling(can)
      onUserPagePublisher(can)
      onViewport(can)
      break
    }

    case 'backOffice': {
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onPageCreateUpdateDelete(can)
      onViewport(can)
      break
    }

    case 'customerPerformance': {
      onAdUnitMediaTypes(can)
      onAdUnitUnfilledRecovery(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainOverridenByYieldbirdUpdate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatus(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersCreateUpdateDelete(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatus(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupPrebidModulesGeneralParamsOwn(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbird(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatus(can)
      onGlobalSetupPriceGenius(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainCreateUpdateDelete(can)
      onGlobalSetupWorkspaceUser(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSampling(can)
      onUserPagePublisher(can)
      onViewport(can)
      break
    }

    case 'customerSuccess': {
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupWorkspaceUser(can)
      onPageCreateUpdateDelete(can)
      onUserPagePublisher(can)
      onViewport(can)
      break
    }

    case 'publisher': {
      onWorkspaceSelect(can)
      break
    }
  }
}
