import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl/types/ability'
import { GET_AD_UNIT_AMAZON_FRAGMENT } from './fragment'
import { Data } from './types'

export const GET_AD_UNIT_AMAZONS = (ability: Ability): TypedDocumentNode<Data> => gql`
  ${GET_AD_UNIT_AMAZON_FRAGMENT(ability)}

  query getAdUnitAmazons(
    $domainAmazonId: ID
    $first: Int
    $last: Int
    $terms: [String!]
    $sort: AdUnitAmazonSortInput
  ) {
    adUnitAmazons(
      domainAmazonId: $domainAmazonId
      first: $first
      last: $last
      terms: $terms
      sort: $sort
    ) {
      nodes {
        ...AdUnitAmazonFragment
      }

      totalCount
    }
  }
`
