import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_PERFORMANCE_REPORT: TypedDocumentNode<Data, Variables> = gql`
  query getPerformanceReport($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        id
        performanceReportUrl
        users {
          id
          email
        }
      }
    }
  }
`
