import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'

export const useCollectInventoryConnectionDirtyRows = (): void => {
  const { inventoryConnectionDirtyRows, setInventoryConnectionDirtyRows } =
    useContext(WorkspaceBidderContext)

  const {
    formState,
    formState: { dirtyFields },
  } = useFormContext<Schema>()

  useEffect(() => {
    if (setInventoryConnectionDirtyRows !== undefined && !_.isEmpty(dirtyFields)) {
      const dirtyRowsIds = _.keys(dirtyFields.adUnitBidders)

      if (!_.isEqual(inventoryConnectionDirtyRows, dirtyRowsIds)) {
        setInventoryConnectionDirtyRows(dirtyRowsIds)
      }
    }
  }, [formState, setInventoryConnectionDirtyRows]) // eslint-disable-line react-hooks/exhaustive-deps
}
