import { ColumnDef } from '@tanstack/react-table'

import { COLUMN_SIZE } from 'components/Table/constants'
import { PrebidModuleAccount } from 'features/globalSetup/types/prebidModule'
import { DomainPrebidModuleSchemaParameter } from 'features/inventory/domain/types/domainPrebidModule'
import { DomainPrebidModuleSlice } from '../../../hooks/useGetPrebidModuleDomains/types'
import { ParamInputCell } from './ParamInputCell'

export const createParamsColumns = ({
  paramsSchema,
  prebidModuleAccount,
}: {
  paramsSchema: DomainPrebidModuleSchemaParameter[]
  prebidModuleAccount?: PrebidModuleAccount
}): ColumnDef<DomainPrebidModuleSlice>[] => {
  if (paramsSchema === undefined || paramsSchema.length === 0) {
    return [
      {
        id: 'hidden',
        meta: { hidden: true },
      },
    ]
  }

  const columns: ColumnDef<DomainPrebidModuleSlice>[] = Object.values(paramsSchema).map(
    (value: DomainPrebidModuleSchemaParameter): ColumnDef<DomainPrebidModuleSlice> => {
      const { label, name, required, tooltip } = value

      let header = label
      if (required) {
        header += '*'
      }

      return {
        accessorKey: `params.${name}`,
        cell: context => <ParamInputCell cellContext={context} />,
        header,
        meta: {
          hasCreamBackground: prebidModuleAccount === 'yieldbird',
          tooltip,
        },
        size: COLUMN_SIZE.MD,
      }
    },
  )

  return columns
}
