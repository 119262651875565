import { RouteObject } from 'react-router-dom'

import { PerformanceGeneralPage } from 'features/performance/pages/PerformanceGeneralPage'
import { PerformancePrebidStackAnalyticsPage } from 'features/performance/pages/PerformancePrebidStackAnalyticsPage'
import { PerformanceProductPage } from 'features/performance/pages/PerformanceProductPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const performanceRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='PerformancePage'
        page={<PerformanceGeneralPage />}
      />
    ),
    path: ROUTES.PERFORMANCE.GENERAL,
  },
  {
    element: (
      <AuthorizedRoute
        access='PerformancePage'
        page={<PerformanceProductPage />}
      />
    ),
    path: ROUTES.PERFORMANCE.PRODUCT,
  },
  {
    element: (
      <AuthorizedRoute
        access='PerformancePage'
        page={<PerformancePrebidStackAnalyticsPage />}
      />
    ),
    path: ROUTES.PERFORMANCE.PREBID_STACK.ANALYTICS,
  },
]
