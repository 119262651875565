import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Schema, ViewportSchema } from '../../forms/ViewportForm/schema'
import { ViewportAttributeDTO } from '../../types/viewportAttributeDTO'
import { mapApiErrors, toDTO } from './api/mapper'
import { UPSERT_VIEWPORTS } from './api/mutation'
import { Data } from './api/types'
import { UseuseUpsertViewports } from './types'

export const useUpsertViewports = (): UseuseUpsertViewports => {
  const { t } = useTranslation('features/globalSetup/viewport', { keyPrefix: 'form' })
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate, { loading }] = useMutation(UPSERT_VIEWPORTS, {
    onCompleted: ({ upsertViewports }: Data): void => {
      if (upsertViewports) {
        const { errors } = upsertViewports

        if (errors && errors.length > 0) {
          setErrors({ viewports: mapApiErrors(errors) })
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else {
          setErrors({})
          enqueueSnackbar(t('success'), { variant: 'success' })
        }
      }
    },
    onError: (): void => {},
  })

  const update = (attributes: ViewportSchema[]): void => {
    mutate({
      variables: {
        input: {
          attributes: attributes.map(
            (attribute: ViewportSchema): ViewportAttributeDTO => toDTO(attribute),
          ),
        },
      },
    })
  }

  return {
    errors,
    loading,
    update,
  }
}
