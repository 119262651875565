import { z } from 'zod'

import { WorkspaceSlice } from 'features/globalSetup/contexts/GeneralPageContext'
import { Ability } from 'providers/casl'

export const schema = z.object({
  id: z.string(),
  name: z.string(),
  priceGeniusAlwaysOn: z.boolean().optional(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (workspace: WorkspaceSlice, ability: Ability): Schema => ({
  ...workspace,
  priceGeniusAlwaysOn: ability.can('read', 'GlobalSetupField-priceGenius')
    ? workspace.priceGeniusAlwaysOn
    : undefined,
})
