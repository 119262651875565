import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_AD_UNIT_BIDDERS } from './api/query'
import { AdUnitBidderSliceDTO } from './api/types'
import { AdUnitBidderSlice, UseGetAdUnitBidders, UseGetAdUnitBiddersProps } from './types'

export const useGetAdUnitBidders = ({
  adUnitId,
}: UseGetAdUnitBiddersProps): UseGetAdUnitBidders => {
  const [adUnitBidders, setAdUnitBidders] = useState<AdUnitBidderSlice[] | undefined>()

  useEffect(() => {
    if (adUnitId) {
      query({
        variables: {
          adUnitId,
        },
      })
    }
  }, [adUnitId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [query] = useLazyQuery(GET_AD_UNIT_BIDDERS, {
    onCompleted: ({ adUnitBidders: { nodes: bidders } }): void => {
      if (bidders) {
        setAdUnitBidders(
          bidders.map((bidder: AdUnitBidderSliceDTO): AdUnitBidderSlice => fromDTO(bidder)),
        )
      }
    },
  })

  return { adUnitBidders }
}
