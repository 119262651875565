import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, RadioGroup, Slider, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { AddEditDomainContext } from '../../contexts/AddEditDomainContext'
import { useLazyLoadRadioGroup } from './hooks/useLazyLoadRadioGroup'
import { useSlowingFactorMarks } from './hooks/useSlowingFactorMarks'
import { SCROLLING_FACTOR_VALUES } from './hooks/useSlowingFactorMarks/constants'
import { useTooltips } from './hooks/useTooltips'
import { useTrafficAffectedMarks } from './hooks/useTrafficAffectedMarks'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/domain')
  const { domain } = useContext(AddEditDomainContext)
  const { control, watch } = useFormContext<Schema>()

  const { lazyLoadingEnabled, scrollSlowingEnabled } = watch()

  const lazyLoadOptions = useLazyLoadRadioGroup()
  const tooltips = useTooltips()
  const trafficAffectedMarks = useTrafficAffectedMarks()
  const slowingFactorMarks = useSlowingFactorMarks()

  return (
    <>
      <Paper>
        <SectionHeader
          bottomSpacing
          subtitle={t('form.viewabilityTools.enabled.description')}
          title={t('form.viewabilityTools.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              name='viewabilityToolsEnabled'
            />
          }
        />

        <BoxForm>
          <Slider
            control={control}
            label={t('form.viewabilityTools.trafficAffected.label')}
            marks={trafficAffectedMarks}
            name='trafficAffected'
            tooltip={{ content: t('form.viewabilityTools.trafficAffected.tooltip') }}
            withInput
          />
        </BoxForm>
      </Paper>

      <Paper>
        <SectionHeader
          bottomSpacing
          subtitle={t('form.viewabilityTools.basicSettings.subtitle')}
          title={t('form.viewabilityTools.basicSettings.title')}
        />

        <BoxForm>
          <Switch
            control={control}
            label={t('form.viewabilityTools.basicSettings.adHolding.label')}
            labelPlacement='start'
            name={'adHoldingEnabled'}
            tooltip={tooltips.adHolding}
          />

          <Switch
            control={control}
            label={t('form.viewabilityTools.basicSettings.scrollSlowingEnabled.label')}
            labelPlacement='start'
            name={'scrollSlowingEnabled'}
            tooltip={tooltips.scrollSlownig}
          />

          {scrollSlowingEnabled && (
            <Slider
              control={control}
              label={t(
                'form.viewabilityTools.basicSettings.scrollSlowingEnabled.options.slowingFactor.label',
              )}
              marks={slowingFactorMarks}
              max={SCROLLING_FACTOR_VALUES.MAX}
              name='slowingFactor'
              step={5}
              tooltip={{
                content: t(
                  'form.viewabilityTools.basicSettings.scrollSlowingEnabled.options.slowingFactor.tooltip',
                ),
              }}
            />
          )}

          <Switch
            control={control}
            label={t('form.viewabilityTools.basicSettings.lazyLoadingEnabled.label')}
            labelPlacement='start'
            name={'lazyLoadingEnabled'}
            tooltip={tooltips.lazyLoading}
          />

          {lazyLoadingEnabled && (
            <>
              <RadioGroup
                control={control}
                name='lazyLoadType'
                options={lazyLoadOptions}
                row
              />

              <NumberField
                control={control}
                label={t(
                  'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.fetchMargin.placeholder',
                )}
                name='fetchMargin'
                tooltip={tooltips.fetchMargin}
              />

              <NumberField
                control={control}
                label={t(
                  'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.renderMargin.placeholder',
                )}
                name='renderMargin'
                tooltip={tooltips.renderMargin}
              />

              <NumberField
                control={control}
                label={t(
                  'form.viewabilityTools.basicSettings.lazyLoadingEnabled.options.mobileScaling.placeholder',
                )}
                name='mobileScaling'
                tooltip={tooltips.mobileScaling}
              />
            </>
          )}
        </BoxForm>
      </Paper>

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={domain!.id} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            product='viewabilityTools'
          />
        )}
      />
    </>
  )
}
