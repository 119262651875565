import { mapDTO } from '@twistezo/ts-dto-mapper'

import { parseSchemaParametersToDTO } from 'features/globalSetup/utils/parameter'
import { DomainPrebidModule } from 'features/inventory/domain/types/domainPrebidModule'
import { Schema, SchemaDomainPrebidModuleAttributes } from '../../../schema'
import { DomainPrebidModuleAttributeSliceDTO, Variables } from './types'

export const toDTO = ({
  canUpdateParams,
  data,
  dirtyRowsIds,
}: {
  canUpdateParams: boolean
  data: Schema
  dirtyRowsIds?: DomainPrebidModule['id'][]
}): Variables =>
  mapDTO<Schema, Variables>({ from: data }).transform((schema: Schema): Variables => {
    const { domainPrebidModules } = schema

    const parsed: DomainPrebidModuleAttributeSliceDTO[] = domainPrebidModulesToDTO(
      domainPrebidModules,
      canUpdateParams,
    )

    let filteredByDirty: DomainPrebidModuleAttributeSliceDTO[] = []
    if (dirtyRowsIds !== undefined && dirtyRowsIds.length > 0) {
      filteredByDirty = parsed.filter(({ id }: DomainPrebidModuleAttributeSliceDTO) =>
        dirtyRowsIds.includes(id),
      )
    }

    return { attributes: filteredByDirty }
  })

const domainPrebidModulesToDTO = (
  domainPrebidModules: Schema['domainPrebidModules'],
  canUpdateParams: boolean,
): DomainPrebidModuleAttributeSliceDTO[] =>
  Object.entries(domainPrebidModules).map(
    ([key, { enabled, params }]: [
      key: keyof Schema['domainPrebidModules'],
      SchemaDomainPrebidModuleAttributes,
    ]): DomainPrebidModuleAttributeSliceDTO => ({
      enabled,
      id: key,
      params: canUpdateParams ? parseSchemaParametersToDTO(params) : undefined,
    }),
  )
