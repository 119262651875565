import validator from 'validator'
import { z } from 'zod'

export const commonSchema = z.object({
  name: z
    .string()
    .refine((value: string): boolean => validator.isFQDN(value), 'Invalid Domain name'),
})

export type CommonSchema = z.infer<typeof commonSchema>
