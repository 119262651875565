import { gql, TypedDocumentNode } from '@apollo/client'

import { ERROR_FRAGMENT } from 'providers/graphql'
import { DomainPrebidModuleAttributesErrors } from './types'

export const DOMAIN_PREBID_MODULE_ATTRIBUTES_ERRORS_FRAGMENT: TypedDocumentNode<DomainPrebidModuleAttributesErrors> = gql`
  ${ERROR_FRAGMENT}

  fragment DomainPrebidModuleAttributesErrorsFragment on DomainPrebidModulesErrors {
    domainPrebidModuleId
    errors {
      ...ErrorFragment
    }
  }
`
