import { gql, TypedDocumentNode } from '@apollo/client'

import { Ability } from 'providers/casl'
import { permitFields } from 'providers/graphql/utils'
import { AdUnitForProductTableDTO } from './types'

export const AD_UNIT_FOR_PRODUCT_TABLE_FRAGMENT = (
  ability: Ability,
): TypedDocumentNode<AdUnitForProductTableDTO> => gql`
  fragment AdUnitForProductTableFragment on AdUnit {
  ${permitFields({
    unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery'),
  })}
    divId
    id
    name
    path
    prebidStackEnabled
    priceGeniusEnabled
    refresherEnabled
    unfilledRecoveryEnabled
    updatedAt
    viewabilityToolsEnabled
  }
`
