import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { SelectOption } from 'components/Select'
import { useGetViewports, ViewportSlice } from 'features/globalSetup/features/viewport'
import { CommonSchema } from 'features/inventory/ad-unit/forms/GeneralForm/schemas/commonSchema'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { MediaTypeViewportsSchema } from '../../../../schemas/mediaTypesSchema'
import { UseMediaTypeViewports, ViewportOptionSlice } from './types'

export const useMediaTypeViewports = (): UseMediaTypeViewports => {
  const { workspaceId } = useWorkspaceParam()
  const { viewports: allViewports } = useGetViewports({ workspaceId })

  const { watch } = useFormContext<CommonSchema>()
  const {
    mediaTypes: { newViewportId, viewportAttributes },
  } = watch()

  const activeViewports: MediaTypeViewportsSchema['viewport'][] = viewportAttributes.map(
    ({ viewport }: MediaTypeViewportsSchema): MediaTypeViewportsSchema['viewport'] => viewport,
  )

  const inactiveViewports: ViewportSlice[] = _.differenceBy(
    allViewports,
    activeViewports,
    'viewportId',
  )

  const options: SelectOption<ViewportOptionSlice>[] = inactiveViewports.map(
    ({ name, viewportId }: ViewportSlice): SelectOption<ViewportOptionSlice> => ({
      label: name,
      value: viewportId,
    }),
  )

  const getNewMediaTypeData = (): [index: number, MediaTypeViewportsSchema] => {
    const template: undefined | ViewportSlice = inactiveViewports.find(
      (viewport: ViewportSlice): boolean => viewport.viewportId === newViewportId,
    )

    if (!template) {
      throw new Error('Could not find Viewport schema')
    }

    const schema: MediaTypeViewportsSchema = {
      ...template,
      viewport: template,
    }

    const position: number = _.sortedIndexBy(activeViewports, template, 'minimumWidth')

    return [position, schema]
  }

  return { getNewMediaTypeData, options }
}
