import { gql, TypedDocumentNode } from '@apollo/client'

import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { Ability } from 'providers/casl/types/ability'
import { permitFields } from 'providers/graphql/utils'
import { AdUnitAmazonSliceDTO } from './types'

export const GET_AD_UNIT_AMAZON_FRAGMENT = (
  ability: Ability,
): TypedDocumentNode<AdUnitAmazonSliceDTO> => gql`
  fragment AdUnitAmazonFragment on AdUnitAmazon {
    adUnit {
      divId
      id
      path
      ${permitFields<AdUnitDTO>({
        unfilledRecoveryAdUnit: ability.can('read', 'AdUnitField-unfilledRecovery'),
      })}
    }
    enabled
    id
    updatedAt
  }
`
